import { shallowMount } from '@vue/test-utils';
import Start from '@/pages/animals_in_order_blind/start';

jest.mock('vue-meta');
jest.mock('vue-i18n', () => ({
  useI18n: jest.fn(() => ({
    t: () => {},
  })),
}));


describe('AnimalsInOrderBlindStart.vue', () => {
  const store = {
    getters: {
      'animalsInOrder/animalSelectionComplete': true,
    },
    dispatch: jest.fn(),
  };

  const global = {
    provide: {
      store,
    },
  };
  it('renders child components', () => {
    const wrapper = shallowMount(Start, { global });

    expect(wrapper.findComponent({ name: 'GameBack' }).exists()).toBe(true);
    expect(wrapper.findComponent({ name: 'AnimalSelection' }).exists()).toBe(true);
    expect(wrapper.findComponent({ name: 'GameConfiguration' }).exists()).toBe(true);
  });
});
